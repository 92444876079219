import {
  Component,
  Input,
  OnDestroy,
  ViewChild,
  SimpleChanges,
  OnChanges,
  OnInit,
} from "@angular/core";
import { CreateLcService } from "src/app/services/create-lc.service";
import { Util, Status } from "src/app/util";
import { Subscription, iif, of } from "rxjs";
import { Document } from "src/app/models/document";
import { LettreCredit } from "src/app/models/lettre-credit";
import { CreatePaymentInstructionsComponent } from "../create-payment-instructions/create-payment-instructions.component";
import { mergeMap, map, delay } from "rxjs/operators";
import { AuthorizationService } from "src/app/core/auth-utils/services/authorization.service";
import { AlertComponent } from "src/app/core/alert/alert.component";
import { LastComment } from "src/app/models/lastComment";
import { ModeleService } from "src/app/services/modele.service";
import { CreateUtil } from "./createUtil";
import * as FileSaver from "file-saver";
import { Router } from "@angular/router";
import { CreateApplicantComponent } from "../create-applicant/create-applicant.component";
import { ClientView } from "src/app/models/client-view";
import { ClientSharedService } from "src/app/services/client-shared-service";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.css"],
})
export class CreateComponent implements OnInit, OnDestroy, OnChanges {
  submitted: boolean;
  saved: boolean = false;
  @Input() model: LettreCredit;
  @Input() summary;
  LIST = "list";
  NEW = "new";
  HIDE_MODAL = "hideModal";
  SHOW_MODAL = "showModal";
  modeleModalData: { showClass: string; type: string } = {
    showClass: this.HIDE_MODAL,
    type: null,
  };
  @Input() lastComment: LastComment;
  @ViewChild("payment_section")
  paymentSectionComponent: CreatePaymentInstructionsComponent;
  @ViewChild("create_alert")
  createAlert: AlertComponent;

  @ViewChild(CreateApplicantComponent)
  createApplicant: CreateApplicantComponent;

  clientPanelOpen: boolean;
  generalPanelOpen: boolean;
  private uploadJoinedSubscription: Subscription;
  private tokenWillExpireSubscription: Subscription;
  currencies: string[];
  display_warning = { isDisplay: false };
  snackbarMessage_warning = "";
  formValidators = {
    isGeneralSectionValid: false,
    isAmoutInfoSectionValid: false,
    isBenefSectionValid: false,
    isClientSectionValid: false,
    isGoodsSectionValid: false,
    isRequiredDocsSectionValid: false,
    isPaymentSectionValid: false,
    isTrasnportSectionValid: false,
    isJoinedDocsSectionValid: false,
  };

  deleteButtonClicked = false;
  public client: ClientView;

  constructor(
    private createLCService: CreateLcService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private modeleService: ModeleService,
    private clientSharedService: ClientSharedService
  ) {}

  ngOnInit() {
    this.client = this.clientSharedService.getClient();
    this.tokenWillExpireSubscription = this.authorizationService
      .tokenWillExpire()
      .subscribe(() => {
        this.display_warning.isDisplay = true;
        this.snackbarMessage_warning =
          "Pensez à sauvegader vos changements , déconnexion après 5 minutes !";
      });
  }
  ngOnDestroy(): void {
    if (this.uploadJoinedSubscription) {
      this.uploadJoinedSubscription.unsubscribe();
    }
    if (this.tokenWillExpireSubscription) {
      this.tokenWillExpireSubscription.unsubscribe();
    }
  }
  recap() {
    this.summary.isSummary = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.verifyAmount();
  }

  // verify amount after opening form if exists.
  verifyAmount() {
    if (this.model.amount && this.model.currency) {
      this.paymentSectionComponent.verifyAmount(
        this.model.amount,
        this.model.currency
      );
    }
  }

  allSectionsValid(): boolean {
    return (
      this.isGeneralSectionValid() &&
      this.isAmoutInfoSectionValid() &&
      this.isBenefSectionValid() &&
      this.isClientSectionValid() &&
      this.isGoodsSectionValid() &&
      this.isRequiredDocsSectionValid() &&
      this.isPaymentSectionValid() &&
      this.isTrasnportSectionValid() &&
      this.isJoinDocsSectionValid()
    );
  }

  isGeneralSectionValid() {
    this.formValidators.isGeneralSectionValid =
      typeof this.model.importationOfGoods === "boolean" &&
      this.model.validityDate &&
      Util.diffDays(0, this.model.validityDate, this.model.addDate) &&
      typeof this.model.validityLocation === "string" &&
      this.model.validityLocation !== "" &&
      typeof this.model.confirmation === "boolean" &&
      typeof this.model.transferability === "boolean";
    return this.formValidators.isGeneralSectionValid;
  }

  isClientSectionValid() {
    this.formValidators.isClientSectionValid =
      this.model.accountClient !== null &&
      this.model.accountClient !== undefined &&
      this.model.accountClient !== "";
    return (
      this.formValidators.isClientSectionValid &&
      this.createApplicant.isAccountSelectionValid()
    );
  }

  isBenefSectionValid() {
    this.formValidators.isBenefSectionValid =
      typeof this.model.beneficiary.name === "string" &&
      this.model.beneficiary.name !== "" &&
      typeof this.model.beneficiaryBankRequired === "boolean";
    return this.formValidators.isBenefSectionValid;
  }

  areTitlesValid(): boolean {
    return (
      this.model.importationTitles.length > 0 &&
      this.model.importationTitles.reduce((_acc, curr): boolean => {
        return (
          typeof curr === "number" &&
          curr !== null &&
          curr !== undefined &&
          curr !== ""
        );
      }, true)
    );
  }

  isGoodsSectionValid() {
    if (!this.model.importationOfGoods) {
      this.model.importationTitles.splice(
        0,
        this.model.importationTitles.length
      );
    }
    this.formValidators.isGoodsSectionValid =
      typeof this.model.goodDescription === "string" &&
      this.model.goodDescription !== "" &&
      (this.model.importationTitles.length === 0 || this.areTitlesValid());
    return this.formValidators.isGoodsSectionValid;
  }

  isAdvanceValid(): boolean {
    const isAdvanceAMountValid =
      this.model.amountAdvance &&
      typeof this.model.amountAdvance === "number" &&
      this.model.amountAdvance >= 0;

    return this.model.advance ? isAdvanceAMountValid : true;
  }

  isPaymentSectionValid(): boolean {
    return (
      this.model.chargesOutside &&
      this.isAdvanceValid() &&
      this.isPaymentSectionValid_() &&
      this.isAmoutInfoSectionValid() &&
      this.isAmountValid()
    );
  }

  isAmountValid(): boolean {
    return this.paymentSectionComponent.isAmountValid;
  }

  isPaymentSectionValid_() {
    let mixedAcceptanceValid = false;
    let mixedDueValid = false;
    let mixedSightValid = false;
    let sumValid = 0;
    if (this.model.paymentType === "SINGLE_PAYMENT") {
      if (this.model.paymentDueType === true) {
        this.formValidators.isPaymentSectionValid =
          typeof this.model.daysDuePayment === "number" &&
          this.model.daysDuePayment >= 0 &&
          typeof this.model.fromDuePayment === "string" &&
          this.model.fromDuePayment !== "";
        return this.formValidators.isPaymentSectionValid;
      } else if (this.model.paymentAtSightType === true) {
        this.formValidators.isPaymentSectionValid = true;
        return true;
      } else if (this.model.paymentByAcceptanceType === true) {
        this.formValidators.isPaymentSectionValid =
          typeof this.model.daysAcceptancePayment === "number" &&
          this.model.daysAcceptancePayment >= 0;
        return this.formValidators.isPaymentSectionValid;
      }
      return false;
    } else if (this.model.paymentType === "MIXED_PAYMENT") {
      mixedAcceptanceValid =
        this.model.paymentByAcceptanceType &&
        typeof this.model.daysAcceptancePayment === "number" &&
        this.model.daysAcceptancePayment >= 0 &&
        typeof this.model.amountAcceptancePayment === "number" &&
        this.model.amountAcceptancePayment >= 0;

      mixedSightValid =
        this.model.paymentAtSightType &&
        typeof this.model.amountSightPayment === "number" &&
        this.model.amountSightPayment >= 0;

      mixedDueValid =
        this.model.paymentDueType &&
        typeof this.model.daysDuePayment === "number" &&
        this.model.daysDuePayment >= 0 &&
        typeof this.model.fromDuePayment === "string" &&
        this.model.fromDuePayment !== "" &&
        typeof this.model.amountDuePayment === "number" &&
        this.model.amountDuePayment >= 0;
    }

    sumValid = mixedAcceptanceValid ? 1 : 0;
    sumValid += mixedDueValid ? 1 : 0;
    sumValid += mixedSightValid ? 1 : 0;

    this.formValidators.isPaymentSectionValid =
      (sumValid === 3 ||
        (sumValid === 2 &&
          !(
            this.model.paymentAtSightType &&
            this.model.paymentByAcceptanceType &&
            this.model.paymentDueType
          ))) &&
      Util.compareAmounts(this.model);
    return this.formValidators.isPaymentSectionValid;
  }

  isTransportationDocumentValid() {
    return (
      !this.model.importationOfGoods || this.isTransportationDocumentValid_()
    );
  }

  isTransportationDocumentValid_() {
    return (
      typeof this.model.transportationDocument.type === "string" &&
      this.model.transportationDocument.type !== "" &&
      ((this.model.transportationDocument.type ===
        "INTERNATIONAL_CONSIGNMENTNOTE" &&
        typeof this.model.transportationDocument.establishedBy === "string" &&
        this.model.transportationDocument.establishedBy !== "") ||
        this.model.transportationDocument.type === "MARITIME_KNOWLEDGE" ||
        this.model.transportationDocument.type === "FLIGHT_LETTTER")
    );
  }

  iscommercialInvoice() {
    return (
      typeof this.model.commercialInvoice.copies === "number" &&
      this.model.commercialInvoice.copies >= 0 &&
      typeof this.model.commercialInvoice.originals === "number" &&
      this.model.commercialInvoice.originals >= 0
    );
  }

  isRequiredDocsSectionValid(): boolean {
    this.formValidators.isRequiredDocsSectionValid =
      this.iscommercialInvoice() &&
      this.isTransportationDocumentValid() &&
      (this.model.respectingValidityDate ||
        this.isSubmissionDeadlineDaysCountValid());
    return this.formValidators.isRequiredDocsSectionValid;
  }

  isSubmissionDeadlineDaysCountValid(): boolean {
    return Util.isSubmissionDeadlineDaysCountValid(this.model);
  }

  isJoinDocsSectionValid(): boolean {
    const documentListEmpty =
      !this.model.documentList || this.model.documentList.length === 0;
    const documentsEmpty =
      !this.model.documents || this.model.documents.length === 0;

    const docs = documentListEmpty
      ? documentsEmpty
        ? []
        : this.model.documents
      : this.model.documentList;

    const fileName = docs === this.model.documents ? "fileName" : "name";
    this.formValidators.isJoinedDocsSectionValid =
      docs.length > 0 &&
      docs.reduce((acc, curr: Document): boolean => {
        return typeof curr[fileName] === "string" && curr[fileName] !== "";
      }, true);
    return this.formValidators.isJoinedDocsSectionValid;
  }

  isAmoutInfoSectionValid(): boolean {
    this.formValidators.isAmoutInfoSectionValid =
      this.model.currency !== null &&
      ((typeof this.model.amountToleranceLess === "number" &&
        typeof this.model.amountToleranceMore === "number" &&
        this.model.amountToleranceLess <= 10 &&
        this.model.amountToleranceMore <= 10) ||
        (this.model.amountToleranceLess === null &&
          this.model.amountToleranceMore === null));
    return this.formValidators.isAmoutInfoSectionValid;
  }

  isIncotermsValid() {
    return (
      typeof this.model.incoterm === "string" &&
      (this.model.incoterm !== "Autre" ||
        (this.model.incoterm === "Autre" &&
          typeof this.model.incotermOther === "string" &&
          this.model.incotermOther !== ""))
    );
  }

  isTrasnportSectionValid(): boolean {
    this.formValidators.isTrasnportSectionValid =
      typeof this.model.expeditionFrom === "string" &&
      this.model.expeditionFrom !== "" &&
      typeof this.model.expeditionTo === "string" &&
      this.model.expeditionTo !== "" &&
      this.model.expeditionLastDate &&
      this.isIncotermsValid() &&
      typeof this.model.partialExpeditions === "boolean" &&
      typeof this.model.transshipment === "boolean";
    return (
      this.formValidators.isTrasnportSectionValid ||
      !this.model.importationOfGoods
    );
  }

  togglePanel(panel: HTMLElement) {
    return Util.togglePanel(panel);
  }

  isLCSaved(): boolean {
    return !this.saved;
  }

  isLCToDelete(): boolean {
    return this.model.id !== null;
  }

  saveLC() {
    // appel create LC
    this.model.event = "SAVED";
    this.model.status = Status.TO_COMPLETE;
    this.createLCService
      .saveLC(this.model, this.client.id)
      .pipe(
        map((res) => {
          this.createAlert.snackbarData.reference = res.requestId;
          this.model.id = res.id;
          this.model.requestId = res.requestId;
          return res;
        })
      )
      .pipe(
        mergeMap((lc) =>
          iif(
            () =>
              this.model.documentList.filter(
                (doc) => doc.file !== null && doc.file !== undefined
              ).length > 0,
            this.createLCService.uploadCredocJoinedDocs(
              lc.id,
              this.client.id,
              lc.requestId,
              this.model.documentList
            ),
            of(null)
          )
        )
      )
      .pipe(
        map((documentList) => {
          if (documentList !== null) {
            this.model.documentList = documentList;
          }
          this.createAlert.snackbarData.display.isDisplay = true;
          this.createAlert.snackbarData.message =
            "Votre dossier a été enregistré avec succès.";
        }),
        delay(6000)
      )
      .subscribe(
        (res) => (this.createAlert.snackbarData.display.isDisplay = false)
      );
    this.saved = true;
  }

  printReport() {
    this.createLCService
      .printReport(this.model.id, this.client.id)
      .subscribe((data) => {
        const fileName: string = data.headers.get("FileName");
        FileSaver.saveAs(data.body, fileName);
      });
  }

  isSectionActive(panel): string {
    const active = panel.style.display === "block";
    return active ? "active" : "";
  }

  isSectionCompleted(indicator) {
    return indicator ? "completed" : "";
  }

  selectModele(modeleId: string) {
    this.modeleService
      .getModele(+modeleId, this.client.id)
      .subscribe((modele) => {
        this.model = CreateUtil.mapModeleToCredoc(modele, this.model);
        this.modeleModalData.showClass = this.HIDE_MODAL;
        this.verifyAmount();
      });
  }

  saveModele(modeleName: string) {
    this.modeleService
      .saveModele(
        CreateUtil.mapCredocToModele(this.model, modeleName),
        this.client.id
      )
      .subscribe(
        (modeleId) => (this.modeleModalData.showClass = this.HIDE_MODAL)
      );
  }

  deleteCredocImport() {
    this.createLCService
      .deleteCredocImport(this.model.id, this.client.id)
      .subscribe(
        (res) => {
          this.router.navigate(["/portail"]);
        },
        (err) => {}
      );
  }
}
