import { Component, OnInit, OnDestroy } from "@angular/core";
import { Util } from "src/app/util";
import { Remdoc } from "src/app/models/remdoc";
import { ReceivedTransferService } from "src/app/services/received-transfer.service";
import { Subscription } from "rxjs";
import { ReceivedTransfer } from "src/app/models/receivedTransfer";
import { Router, NavigationEnd } from "@angular/router";
import * as FileSaver from "file-saver";
import { ClientView } from "src/app/models/client-view";
import { ClientSharedService } from "src/app/services/client-shared-service";
import { DataService } from "src/app/services/data.service";
import { ExpirService } from "src/app/services/expir.service";
import { ProrogService } from "src/app/services/prorog.service";
import { HomeService } from "src/app/services/home.service";
import { TasksComponent } from "src/app/core/tasks/tasks.component";
import { TasksService } from "src/app/services/tasks.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-rt-list",
  templateUrl: "./rt-list.component.html",
  styleUrls: ["./rt-list.component.css"],
})
export class RtListComponent
  extends TasksComponent
  implements OnInit, OnDestroy
{
  public rtList: Array<ReceivedTransfer> = [];
  private rtListSubscription: Subscription;
  private navigationSubscription: Subscription;
  public client: ClientView;
  constructor(
    private receivedTransferService: ReceivedTransferService,
    private tasksService: TasksService,
    private userService: UserService,
    dataService: DataService,
    expirService: ExpirService,
    router: Router,
    prorogService: ProrogService,
    homeService: HomeService,
    clientSharedService: ClientSharedService
  ) {
    super(
      homeService,
      dataService,
      router,
      prorogService,
      expirService,
      clientSharedService
    );

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    this.client = this.clientSharedservice.getClient();
    this.getRtList();
  }

  setStatusColor(key: string) {
    return Util.setStatus("credoc_list", key).color;
  }

  setStatusText(key: string) {
    return Util.setStatus("credoc_list", key).text;
  }

  setStatusTaskText(key: string) {
    return Util.setStatus("tasks", key).text;
  }

  displayTask(rt: ReceivedTransfer) {
    this.tasksService
      .getTaskByRequestId(rt.requestId, this.client.id)
      .subscribe((task) => {
        this.customRoute(task);
      });
  }

  displayBtnActionInTable(status: string) {
    return (
      ([
        "TO_COMPLETE",
        "TO_REGULARIZE",
        "TO_REGULARIZE_MO",
        "TO_TREAT",
      ].includes(status) &&
        this.userService.hasAnyRoles(["TRANSFER_RECEIVED_INITIATOR"])) ||
      ([
        "TO_SIGN",
        "TO_REGULARIZE",
        "TO_REGULARIZE_MO",
        "TO_VALIDATE",
        "TO_TREAT",
      ].includes(status) &&
        this.userService.hasAnyRoles(["TRANSFER_RECEIVED_VALIDATOR"]))
    );
  }

  printRT(rtId) {
    this.receivedTransferService
      .printReport(rtId, this.client.id)
      .subscribe((data) => {
        const fileName: string = data.headers.get("FileName");
        FileSaver.saveAs(data.body, fileName);
      });
  }

  getRtList(): void {
    this.rtListSubscription = this.receivedTransferService
      .getRtList(this.client.id)
      .subscribe((resp) => {
        this.rtList = resp;
      });
  }
  openRtRecap(rt: Remdoc) {}

  ngOnDestroy(): void {
    if (this.rtListSubscription) {
      this.rtListSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
