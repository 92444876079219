import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import { Remdoc } from "src/app/models/remdoc";
import { RemdocService } from "src/app/services/remdoc.service";
import { Observable, Subscription } from "rxjs";
import { CreateLcService } from "src/app/services/create-lc.service";
import { HomeService } from "src/app/services/home.service";
import { Task } from "src/app/models/task";
import { Util, Status, Products } from "src/app/util";
import { Router, NavigationEnd } from "@angular/router";
import { TasksComponent } from "../tasks/tasks.component";
import { ExpirService } from "src/app/services/expir.service";
import { ProrogService } from "src/app/services/prorog.service";
import { DataService } from "src/app/services/data.service";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";
import { ReceivedTransferService } from "src/app/services/received-transfer.service";
import { ReceivedTransfer } from "src/app/models/receivedTransfer";
import { NotificationService } from "src/app/services/notification.service";
import { IssuedTransferService } from "src/app/services/issued-transfer.service";
import * as Highcharts from "highcharts";
import { HighchartsChartComponent } from "highcharts-angular";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { Client } from "src/app/models/client";
import { ClientSharedService } from "src/app/services/client-shared-service";
import { ClientView } from "src/app/models/client-view";
import { ClientService } from "src/app/services/client.service";
const globalChartConf = {
  chart: {
    backgroundColor: "transparent",
  },
  title: {
    text: undefined,
  },
  tooltip: {
    pointFormat: "<b>{point.y}</b> {series.name}",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      borderColor: "#fdfdfe",
      borderWidth: 4,
    },
  },

  credits: {
    enabled: false,
  },
};

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent extends TasksComponent implements OnInit, OnDestroy {
  remdocList: Array<Remdoc>;
  lcs;
  tasks: Array<Task>;
  receivedTransfers: Array<any>;
  transferIssueds: Array<any>;
  tasksCount = 0;
  remdocCount = 0;
  credocCount = 0;
  fullName: string;
  receivedTransferCount = 0;
  transferIssuedCount = 0;
  highcharts = Highcharts;
  @ViewChild("roundTasksChart") roundTasksChart: HighchartsChartComponent;
  @ViewChild("roundProductsChart") roundProductsChart: HighchartsChartComponent;
  page = { currentPage: 0, totalPages: null, totalElements: null };
  public today: number = new Date().getTime();
  private remdocListSubscription: Subscription;
  private navigationSubscription: Subscription;
  private getReceivedTransferSubscription: Subscription;
  private getIssuedTransferSubscription: Subscription;
  private getIssuedTransferCountSubscription: Subscription;
  private isNotificationRefreshedSubscription: Subscription;
  public client: ClientView;
  tasksArr = [
    {
      key: Status.TO_SIGN,
      y: 0,
    },
    {
      key: Status.TO_COMPLETE,
      y: 0,
    },
    {
      key: Status.TO_TREAT,
      y: 0,
    },
    {
      key: Status.TO_VALIDATE,
      y: 0,
    },
    {
      key: Status.TO_REGULARIZE,
      y: 0,
    },
  ];

  productsArr = [
    {
      key: Products.CREDOC_IMPORT,
      color: "#f28a8a",
      name: "Crédit documentaire",
      y: 0,
    },
    {
      key: Products.REMDOC_IMPORT,
      color: "#43b1d0",
      name: "Remse documentaire",
      y: 0,
    },
    {
      key: Products.TRANSFER_ISSUED,
      color: "#a694bf",
      name: "Virement émis",
      y: 0,
    },
    {
      key: Products.TRANSFER_RECEIVED,
      color: "#59d2da",
      name: "Virement reçu",
      y: 0,
    },
  ];

  public clientList: Array<ClientView> = [];
  chartConstructor = "chart";

  tasksChartOptions: Highcharts.Options = {
    ...globalChartConf,
    series: [
      {
        name: "dossier(s)",
        type: "pie",
        innerSize: "92%",
        data: this.tasksArr.map((task) => {
          task["color"] = Util.setStatus("tasks", task.key).color;
          task["name"] =
            "À " + Util.setStatus("tasks", task.key).text.toLocaleLowerCase();
          return task;
        }),
      },
    ],
  }; // required
  productsChartOptions: Highcharts.Options = {
    ...globalChartConf,
    series: [
      {
        name: "dossier(s)",
        type: "pie",
        innerSize: "92%",
        data: this.productsArr,
      },
    ],
  };
  updateFlag = false; // optional boolean
  oneToOneFlag = true; // optional boolean, defaults to false
  runOutsideAngular = false; // optional boolean, defaults to false

  Math: any;
  slideConfig = {
    dots: true,
    infinite: false,
    speed: 300,
    variableWidth: true,
    focusOnSelect: false,
    arrows: false,
    slidesToShow: 6,
    swipeToSlide: true,
  };
  constructor(
    private remdocService: RemdocService,
    private createLcService: CreateLcService,
    private userService: UserService,
    private receivedTransferService: ReceivedTransferService,
    private issuedTransferService: IssuedTransferService,
    private notificationService: NotificationService,
    private clientService: ClientService,
    homeService: HomeService,
    router: Router,
    dataService: DataService,
    proroService: ProrogService,
    expirService: ExpirService,
    clientSharedService: ClientSharedService
  ) {
    super(
      homeService,
      dataService,
      router,
      proroService,
      expirService,
      clientSharedService
    );

    this.Math = Math;
    this.navigationSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  private init() {
    this.clientService.getClients().subscribe((clients) => {
      this.clientList = clients;

      this.clientService.getCustomerClient().subscribe((obj) => {
        if (obj && obj.customerNumber) {
          if (obj.customerNumber.includes("customerNumber")) {
            this.client = this.clientList.find((client) => {
              return (
                client.customerNumber ===
                JSON.parse(obj.customerNumber).customerNumber
              );
            });
          } else {
            this.client = this.clientList.find((client) => {
              return client.customerNumber === obj.customerNumber;
            });
          }
        } else {
          this.client = this.clientList[0];
        }

        this.userService
          .initUserCredentialsInLocalStorage(this.client.id)
          .toPromise()
          .then((rols) => {
            this.getListRemDocIfUserHasRemdocRole();
            this.getListCredocIfUserHasCredocRole();
            this.getListTransferReceivedIfUserHasTransferReceivedRole();
            this.getListTransferIssuedIfUserHasTransferIssuedRole();
            this.userService
              .getFullName()
              .subscribe((fullName) => (this.fullName = fullName));
          });
        //this.getTasks();

        this.isNotificationRefreshedSubscription =
          this.notificationService.isNotificationRefreshed.subscribe(
            (_value) => {
              this.getTasks();
            }
          );
      });
    });
  }

  ngOnInit() {}

  getListRemDocIfUserHasRemdocRole() {
    if (this.userService.hasAnyRoles(environment.remdocRoles)) {
      this.getListRemdoc();
    }
  }

  getListCredocIfUserHasCredocRole() {
    if (this.userService.hasAnyRoles(environment.credocRoles)) {
      this.getLCs();
    }
  }

  getListTransferReceivedIfUserHasTransferReceivedRole() {
    if (this.userService.hasAnyRoles(environment.transferReceivedRoles)) {
      this.getReceivedTransferList();
    }
  }

  getListTransferIssuedIfUserHasTransferIssuedRole() {
    if (this.userService.hasAnyRoles(environment.transferIssuedRoles)) {
      this.getIssuedList();
      this.getCountListTransferIssued();
    }
  }

  ngOnDestroy(): void {
    if (this.remdocListSubscription) {
      this.remdocListSubscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.getReceivedTransferSubscription) {
      this.getReceivedTransferSubscription.unsubscribe();
    }
    if (this.getIssuedTransferSubscription) {
      this.getIssuedTransferSubscription.unsubscribe();
    }
    if (this.getIssuedTransferCountSubscription) {
      this.getIssuedTransferCountSubscription.unsubscribe();
    }
    if (this.isNotificationRefreshedSubscription) {
      this.isNotificationRefreshedSubscription.unsubscribe();
    }
  }

  newCredoc() {
    return this.router.navigate(["/lc"]);
  }

  newBeneficiary() {
    return this.router.navigate(["/beneficiary"]);
  }

  listModele() {
    return this.router.navigate(["lc/models/list"]);
  }

  getListRemdoc(): void {
    this.remdocListSubscription = this.remdocService
      .getListRemdoc(this.client.id)
      .subscribe((resp) => {
        this.remdocList = resp;
        this.remdocCount = this.remdocList.length;
        this.updateRoundProductsChart(Products.REMDOC_IMPORT);
        this.remdocList = this.remdocList.slice(0, 5);
      });
  }

  getReceivedTransferList() {
    this.getReceivedTransferSubscription = this.receivedTransferService
      .getRtList(this.client.id)
      .subscribe((rts) => {
        this.receivedTransfers = rts;
        this.receivedTransfers = this.receivedTransfers.sort(
          (a: ReceivedTransfer, b: ReceivedTransfer) => {
            return (
              new Date(b.addDate).getTime() - new Date(a.addDate).getTime()
            );
          }
        );
        this.receivedTransferCount = this.receivedTransfers.length;
        this.updateRoundProductsChart(Products.TRANSFER_RECEIVED);
        this.receivedTransfers = this.receivedTransfers.slice(0, 5);
      });
  }

  getIssuedList(): void {
    this.getIssuedTransferSubscription = this.issuedTransferService
      .getIssuedTransferList(0, "", this.client.id)
      .subscribe((resp) => {
        this.transferIssueds = resp["contentList"];
        this.transferIssueds = this.transferIssueds.slice(0, 5);
      });
  }

  getCountListTransferIssued(): void {
    this.getIssuedTransferCountSubscription = this.issuedTransferService
      .getCountIssuedTransferList(this.client.id)
      .subscribe((resp) => {
        this.transferIssuedCount = resp;
        this.updateRoundProductsChart(Products.TRANSFER_ISSUED);
      });
  }

  getLCs(): void {
    this.createLcService
      .getLCs(this.client.id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((lcs) => {
        this.transformAndSortLcs(lcs);
      });
  }

  transformAndSortLcs(lcs): void {
    const sortable = [];
    Object.entries(lcs).forEach(([_key, value]) => {
      sortable.push({ ...value[0] });
    });
    this.credocCount = sortable.length;
    this.updateRoundProductsChart(Products.CREDOC_IMPORT);
    this.lcs = sortable.slice(0, 5);
  }

  getOperationLablel(operation: string) {
    return Util.setOperationText(operation);
  }

  setStatusByViewText(view: string, key: string) {
    return Util.setStatus(view, key).text;
  }

  getTasks(): void {
    this.homeService
      .getTasks(this.client.id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((tasks) => {
        this.tasks = tasks;
        this.tasks = this.tasks.sort((a: Task, b: Task) => {
          return (
            new Date(b.createTime).getTime() - new Date(a.createTime).getTime()
          );
        });
        this.updateRoundTasksChart();
        //this.tasks = this.tasks.slice(0, 10);
        this.tasks = this.tasks.map((task) => {
          task["productColor"] = Util.getProduct(task.requestId).color;
          task["productIcon"] = Util.getProduct(task.requestId).icon;
          task["statusColor"] = Util.setStatus(
            "tasks",
            task.taskDefinitionKey
          ).color;
          return task;
        });
      });
  }

  updateRoundTasksChart(): void {
    this.tasksArr.forEach((item) => {
      item.y = 0;
    });
    this.tasks.forEach((item) => {
      switch (item.taskDefinitionKey) {
        case Status.TO_SIGN:
          this.tasksArr[0].y += 1;
          break;
        case Status.TO_COMPLETE:
          this.tasksArr[1].y += 1;
          break;
        case Status.TO_TREAT:
          this.tasksArr[2].y += 1;
          break;
        case Status.TO_VALIDATE:
          this.tasksArr[3].y += 1;
          break;
        case Status.TO_REGULARIZE:
        case Status.TO_REGULARIZE_MO:
          this.tasksArr[4].y += 1;
          break;
      }
    });
    this.roundTasksChart.options = this.tasksChartOptions;
  }

  updateRoundProductsChart(product: string): void {
    switch (product) {
      case Products.CREDOC_IMPORT:
        this.productsArr[0].y = this.credocCount;
        break;
      case Products.REMDOC_IMPORT:
        this.productsArr[1].y = this.remdocCount;
        break;
      case Products.TRANSFER_ISSUED:
        this.productsArr[2].y = this.transferIssuedCount;
        break;
      case Products.TRANSFER_RECEIVED:
        this.productsArr[3].y = this.receivedTransferCount;
        break;
    }
    this.roundProductsChart.options = this.productsChartOptions;
  }

  round(nbr: number) {
    return Math.round(nbr);
  }

  getTypeClass(key: string) {
    if ((key as string).includes("RDI")) {
      return "lightGreen";
    }
    if ((key as string).includes("VRI")) {
      return "lightBlue";
    }
    return "lightRed";
  }

  totalCounts(data) {
    let total = 0;
    data.forEach((d) => {
      total += parseInt(d.y, 10);
    });
    return total;
  }

  getCountAllRequests() {
    return (
      this.remdocCount +
      this.credocCount +
      this.transferIssuedCount +
      this.receivedTransferCount
    );
  }
}
